var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        "aria-labelledby": _vm.iconName,
        role: "presentation"
      },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("title", { attrs: { id: _vm.iconName, lang: "en" } }, [
        _vm._v(_vm._s(_vm.iconName) + " icon")
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: _vm.iconColor } }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }