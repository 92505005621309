var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", function() {
        return [
          _c(
            "div",
            {
              staticClass: "delivery__header",
              class: {
                "delivery__need-mobile": _vm.needMobile,
                "delivery__in-header": _vm.inHeader
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "delivery__icon",
                  attrs: {
                    width: "22",
                    height: "22",
                    viewBox: "0 0 22 22",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showCitySelection.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M19 8.4C19 13.0392 11 21 11 21C11 21 3 13.0392 3 8.4C3 3 6.0908 0 11 0C15.9092 0 19 3 19 8.4Z",
                      fill: "#00aafa"
                    }
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: { cx: "11", cy: "8", r: "4", fill: "white" }
                  })
                ]
              ),
              _vm._v(" "),
              !_vm.mobileHidden
                ? _c(
                    "button",
                    {
                      staticClass: "header_city",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showCitySelection.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "icon-base",
                        {
                          attrs: {
                            "icon-name": "icon-city",
                            height: "14",
                            width: "11"
                          }
                        },
                        [_c("icon-city")],
                        1
                      ),
                      _vm._v(" "),
                      _vm.cityNamePrefix
                        ? _c("span", [_vm._v(_vm._s(_vm.cityNamePrefix))])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.currentCity) + "\n      ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "delivery__title delivery__title--pointer",
                  class: { mobile__hidden: _vm.mobileHidden }
                },
                [
                  _vm.title
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "delivery__text delivery__text-description"
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "delivery__text-city-wrapper" }, [
                    _vm.cityNamePrefix
                      ? _c(
                          "span",
                          {
                            staticClass: "delivery__text delivery__text--prefix"
                          },
                          [_vm._v(_vm._s(_vm.cityNamePrefix))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "delivery__text--button",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showCitySelection.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "delivery__text delivery__text--blue delivery__text--city"
                          },
                          [_vm._v(_vm._s(_vm.currentCity))]
                        ),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "10",
                              height: "6",
                              viewBox: "0 0 10 6",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M9 1L5 5L1 1",
                                stroke: "#373737",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      }),
      _vm._v(" "),
      _vm.showPopup
        ? _c(
            "MobilePopup",
            { attrs: { transition: "slide" }, on: { close: _vm.closePopup } },
            [
              _c("div", { staticClass: "city-selection-popup--mobile" }, [
                _c("h3", { staticClass: "city-selection-popup__title" }, [
                  _vm._v("Выбор города")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "city-selection-popup__input-wrapper" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchValue,
                          expression: "searchValue"
                        }
                      ],
                      staticClass: "city-selection-popup__input",
                      attrs: { name: "city" },
                      domProps: { value: _vm.searchValue },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchValue = $event.target.value
                          },
                          _vm.loadCities
                        ]
                      }
                    }),
                    _vm._v(" "),
                    !_vm.searchValue
                      ? _c(
                          "label",
                          {
                            staticClass: "city-selection-popup__placeholder",
                            attrs: { for: "city" }
                          },
                          [_vm._v(_vm._s(_vm.currentCity))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchValue
                      ? _c("label", {
                          staticClass: "city-selection-popup__closeBtn",
                          on: {
                            click: function($event) {
                              _vm.searchValue = ""
                            }
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                !_vm.searchValue
                  ? _c(
                      "ul",
                      { staticClass: "city-selection-popup__default-cities" },
                      _vm._l(_vm.fastCities, function(city) {
                        return _c(
                          "li",
                          {
                            key: city.id,
                            staticClass: "city-selection-popup__default-city",
                            on: {
                              click: function($event) {
                                return _vm.changeCity(city.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(city.name) + "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "ul",
                      { staticClass: "city-selection-popup__city-dadata" },
                      _vm._l(_vm.cities, function(city) {
                        return _c(
                          "li",
                          {
                            key: city.id,
                            on: {
                              click: function($event) {
                                return _vm.changeCity(city.id)
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v(_vm._s(city.name))]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(city.fullName))])
                          ]
                        )
                      }),
                      0
                    )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile && _vm.isCitySelection
        ? _c(
            "Popup",
            { staticClass: "c-city-selection", on: { close: _vm.closePopup } },
            [
              _c("div", { staticClass: "city-selection-popup" }, [
                _c("h3", { staticClass: "city-selection-popup__title" }, [
                  _vm._v("Выбор города")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "city-selection-popup__input-wrapper" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchValue,
                          expression: "searchValue"
                        }
                      ],
                      staticClass: "city-selection-popup__input",
                      attrs: { name: "city" },
                      domProps: { value: _vm.searchValue },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchValue = $event.target.value
                          },
                          _vm.loadCities
                        ],
                        focus: function($event) {
                          _vm.isInputActive = true
                        },
                        blur: function($event) {
                          _vm.isInputActive = false
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "city-selection-popup__placeholder",
                        class: {
                          "city-selection-popup__placeholder--active":
                            _vm.searchValue
                        },
                        attrs: { for: "city" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.isInputActive || _vm.searchValue
                              ? "Ваш город"
                              : _vm.currentCity
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.searchValue
                      ? _c("label", {
                          staticClass: "city-selection-popup__closeBtn",
                          on: {
                            click: function($event) {
                              _vm.searchValue = ""
                            }
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                !_vm.searchValue
                  ? _c(
                      "ul",
                      { staticClass: "city-selection-popup__default-cities" },
                      _vm._l(_vm.fastCities, function(city) {
                        return _c(
                          "li",
                          {
                            key: city.id,
                            staticClass: "city-selection-popup__default-city",
                            on: {
                              click: function($event) {
                                return _vm.changeCity(city.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(city.name) + "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "ul",
                      { staticClass: "city-selection-popup__city-dadata" },
                      _vm._l(_vm.cities, function(city) {
                        return _c(
                          "li",
                          {
                            key: city.id,
                            on: {
                              click: function($event) {
                                return _vm.changeCity(city.id)
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v(_vm._s(city.name))]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(city.fullName))])
                          ]
                        )
                      }),
                      0
                    )
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }