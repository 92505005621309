<script>
import { Vue } from 'src/apps/vue';
import { IMaskComponent } from 'vue-imask';
import { URL_MEDICAL_RECORD } from 'src/constants/backend';

export default {
  name: 'MyAcuvue',
  components: {
    IMaskComponent,
  },
  props: {
    medicalRecordLink: {
      type: String,
      default: URL_MEDICAL_RECORD,
    },
  },
  data() {
    return {
      phone: '',
      phoneActive: true,
      code: '',
      errorCode: '',

      showCodeScreen: false,
      showPhoneScreen: true,
      showSuccessScreen: false,
      showErrorScreen: false,
      popupError: false,

      isResendSms: true,
      isTablet: false,

      showAuthMyAcuvue: false,
      target: '',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },

    // Проверяет регистрацию пользователя в ПЛ MyAcuvue и отправляет пинкод по смс
    async register(event) {
      event.preventDefault();
      this.isResendSms = false;
      globalThis.emitter.emit('showPreloader', true);
      try {
        await Vue.$http.post('/api/myacuvue/auth/register', { phone: this.phone }).then(response => {
          if (response.data.registered === true) {
            this.showCodeScreen = true;
            this.phoneActive = false;
          } else {
            this.showErrorScreen = true;
          }
          this.showPhoneScreen = false;
          this.isResendSms = true;
        });
      } catch (e) {
        console.error(e);
      } finally {
        globalThis.emitter.emit('showPreloader', false);
      }
    },

    // Проверяет пинкод из смс и авторизует пользователя
    async authorization(event) {
      event.preventDefault();
      globalThis.emitter.emit('showPreloader', true);

      try {
        const data = {
          phone: this.phone,
          pin: this.code,
        };
        await Vue.$http.post('/api/myacuvue/auth/login', data).then(async response => {
          this.showSuccessScreen = true;
          this.showCodeScreen = false;
          this.redirect(response.data.url);
        });
      } catch (error) {
        if (error.response.data.message) {
          this.errorCode = error.response.data.message;
        }
      } finally {
        globalThis.emitter.emit('showPreloader', false);
      }
    },

    redirect(url = '') {
      if (this.target === 'cart') {
        this.close();
        this.updateContext();
      } else {
        window.location.href = url;
      }
    },

    async updateContext() {
      const isCreateContext = false;
      await this.$store.dispatch('checkout/bootstrap', { isCreateContext });

      if (this.isTablet) {
        await this.$store.dispatch('profile/fetchProfile');
      }
    },

    showDoctorAppointment() {
      window.location = this.medicalRecordLink;
    },

    changePin() {
      this.errorCode = '';
    },

    hiddenPopup(e) {
      if (e.target === this.$refs.myacuvuePopup) {
        this.$emit('close');
      }
    },
    initMya(props) {
      const { isShow, target } = props;
      this.showAuthMyAcuvue = isShow || false;
      this.target = target || '';
    },
    resizeHandler() {
      this.isTablet = window.innerWidth < 1280;
    },
  },
  computed: {
    isFormCheckButton() {
      return this.phone.length === 11;
    },
    showPhoneField() {
      return !this.showSuccessScreen && !this.showErrorScreen;
    },
  },
  mounted() {
    globalThis.emitter.on('showAuthMyAcuvuePopup', this.initMya);
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    globalThis.emitter.off('showAuthMyAcuvuePopup', this.initMya);
    window.removeEventListener('resize', this.resizeHandler);
  },
};
</script>

<template>
  <div
    v-if="showAuthMyAcuvue"
    class="myacuvue-popup open-popup"
    id="myacuvuePopup"
    @click="hiddenPopup"
    ref="myacuvuePopup"
  >
    <div class="popup-body">
      <div class="popup-button-close" @click="close">
        <img src="/frontend-new/assets/icons/cross.png" alt="close-popup" />
      </div>
      <div class="popup-header">
        <h4>Подтверждение номера телефона</h4>
        <div class="popup-message popup-success" v-if="showSuccessScreen">
          <p>
            Вы успешно авторизовались в&nbsp;программе лояльности <span>MyACUVUE<sup>®</sup></span>
          </p>
        </div>
        <div class="popup-message popup-error" v-if="showErrorScreen">
          <p>
            Для того чтобы участвовать в&nbsp;программе лояльности <span>MyACUVUE<sup>®</sup></span>
          </p>
          <button class="popup-button myacuvue__button-doctor" @click="showDoctorAppointment">
            Запишитесь к врачу
          </button>
        </div>
      </div>
      <div class="popup-form-check">
        <div class="popup-input" v-if="showPhoneField">
          <IMaskComponent
            :class="[{ disable: !phoneActive }, 'js-phone-mask']"
            placeholder="+7 (___) ___-__-__"
            v-model="phone"
            :mask="'+{7} (000) 000-00-00'"
            type="tel"
            radix="."
            :unmask="true"
            autocomplete="off"
            :disabled="!phoneActive"
          />
        </div>
        <div class="popup-input" id="acceptPrivacyWrapper" v-if="showPhoneScreen">
          <label>
            Предоставляя свои персональные данные, вы подтверждаете, что вам больше 18 лет, и соглашаетесь с
            <a target="_blank" href="/public-oferta/">офертой</a> и
            <a target="_blank" href="/personalnye-dannye/">политикой конфиденциальности</a>.
          </label>
        </div>
        <button
          v-if="showPhoneScreen"
          :class="[{ disable: !isFormCheckButton }, 'popup-button', 'js-phone-btn']"
          type="submit"
          id="formCheckButton"
          :disabled="!isFormCheckButton"
          @click="register"
        >
          Получить код по SMS
        </button>
      </div>
      <div class="popup-form-auth" v-if="showCodeScreen" @submit="authorization">
        <div class="popup-input">
          <input
            :class="[{ invalid: errorCode }]"
            type="code"
            placeholder=""
            v-model="code"
            @input="changePin"
            autocomplete="off"
          />
          <small :class="[{ red: errorCode }]">Введите пароль из SMS</small>
        </div>
        <button class="popup-button" @click="authorization">Подтвердить</button>
        <div class="popup-form-auth-repeat">
          <button :class="[{ disable: !isResendSms }, 'popup-button']" @click="register" :disabled="!isResendSms">
            Отправить код повторно
          </button>
          <small class="">Код действителен в течение <span>15 минут</span></small>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
