var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAuthMyAcuvue
    ? _c(
        "div",
        {
          ref: "myacuvuePopup",
          staticClass: "myacuvue-popup open-popup",
          attrs: { id: "myacuvuePopup" },
          on: { click: _vm.hiddenPopup }
        },
        [
          _c("div", { staticClass: "popup-body" }, [
            _c(
              "div",
              { staticClass: "popup-button-close", on: { click: _vm.close } },
              [
                _c("img", {
                  attrs: {
                    src: "/frontend-new/assets/icons/cross.png",
                    alt: "close-popup"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "popup-header" }, [
              _c("h4", [_vm._v("Подтверждение номера телефона")]),
              _vm._v(" "),
              _vm.showSuccessScreen
                ? _c("div", { staticClass: "popup-message popup-success" }, [
                    _vm._m(0)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showErrorScreen
                ? _c("div", { staticClass: "popup-message popup-error" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "popup-button myacuvue__button-doctor",
                        on: { click: _vm.showDoctorAppointment }
                      },
                      [_vm._v("\n          Запишитесь к врачу\n        ")]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "popup-form-check" }, [
              _vm.showPhoneField
                ? _c(
                    "div",
                    { staticClass: "popup-input" },
                    [
                      _c("IMaskComponent", {
                        class: [{ disable: !_vm.phoneActive }, "js-phone-mask"],
                        attrs: {
                          placeholder: "+7 (___) ___-__-__",
                          mask: "+{7} (000) 000-00-00",
                          type: "tel",
                          radix: ".",
                          unmask: true,
                          autocomplete: "off",
                          disabled: !_vm.phoneActive
                        },
                        model: {
                          value: _vm.phone,
                          callback: function($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPhoneScreen
                ? _c(
                    "div",
                    {
                      staticClass: "popup-input",
                      attrs: { id: "acceptPrivacyWrapper" }
                    },
                    [_vm._m(2)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPhoneScreen
                ? _c(
                    "button",
                    {
                      class: [
                        { disable: !_vm.isFormCheckButton },
                        "popup-button",
                        "js-phone-btn"
                      ],
                      attrs: {
                        type: "submit",
                        id: "formCheckButton",
                        disabled: !_vm.isFormCheckButton
                      },
                      on: { click: _vm.register }
                    },
                    [_vm._v("\n        Получить код по SMS\n      ")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.showCodeScreen
              ? _c(
                  "div",
                  {
                    staticClass: "popup-form-auth",
                    on: { submit: _vm.authorization }
                  },
                  [
                    _c("div", { staticClass: "popup-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code"
                          }
                        ],
                        class: [{ invalid: _vm.errorCode }],
                        attrs: {
                          type: "code",
                          placeholder: "",
                          autocomplete: "off"
                        },
                        domProps: { value: _vm.code },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.code = $event.target.value
                            },
                            _vm.changePin
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("small", { class: [{ red: _vm.errorCode }] }, [
                        _vm._v("Введите пароль из SMS")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "popup-button",
                        on: { click: _vm.authorization }
                      },
                      [_vm._v("Подтвердить")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "popup-form-auth-repeat" }, [
                      _c(
                        "button",
                        {
                          class: [
                            { disable: !_vm.isResendSms },
                            "popup-button"
                          ],
                          attrs: { disabled: !_vm.isResendSms },
                          on: { click: _vm.register }
                        },
                        [_vm._v("\n          Отправить код повторно\n        ")]
                      ),
                      _vm._v(" "),
                      _vm._m(3)
                    ])
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          Вы успешно авторизовались в программе лояльности "),
      _c("span", [_vm._v("MyACUVUE"), _c("sup", [_vm._v("®")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          Для того чтобы участвовать в программе лояльности "),
      _c("span", [_vm._v("MyACUVUE"), _c("sup", [_vm._v("®")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v(
        "\n          Предоставляя свои персональные данные, вы подтверждаете, что вам больше 18 лет, и соглашаетесь с\n          "
      ),
      _c("a", { attrs: { target: "_blank", href: "/public-oferta/" } }, [
        _vm._v("офертой")
      ]),
      _vm._v(" и\n          "),
      _c("a", { attrs: { target: "_blank", href: "/personalnye-dannye/" } }, [
        _vm._v("политикой конфиденциальности")
      ]),
      _vm._v(".\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", {}, [
      _vm._v("Код действителен в течение "),
      _c("span", [_vm._v("15 минут")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }