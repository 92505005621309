var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", { staticClass: "icon-city" }, [
    _c("path", {
      attrs: {
        d:
          "M10 5.30769C10 8.89744 5.5 13 5.5 13C5.5 13 1 9.10256 1 5.30769C1 2.92862 3.01472 1 5.5 1C7.98528 1 10 2.92862 10 5.30769Z",
        stroke: "#00aafa",
        "stroke-width": "1.2",
        "stroke-linejoin": "round"
      }
    }),
    _vm._v(" "),
    _c("circle", {
      attrs: {
        cx: "5.5",
        cy: "5.5",
        r: "1.5",
        fill: "#00aafa",
        stroke: "#00aafa",
        "stroke-width": "1.2"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }