<script>
import { mapGetters, mapState } from 'vuex';
import ClientOnly from 'vue-client-only';

export default {
  props: {
    isCart: Boolean,
    isMain: Boolean,
  },

  components: {
    ClientOnly,
  },

  computed: {
    ...mapGetters('cart', { cart: 'values' }),
    ...mapGetters('profile', { profile: 'fields' }),
    ...mapGetters('header', ['clientInited']),
    ...mapState('checkout', ['contextItems']),
    ...mapGetters('header', ['catalogMenu']),

    hasItems() {
      return this.cart.length > 0 || this.contextItems.length > 0;
    },

    isLogged() {
      return !this.profile.isGuest;
    },

    url() {
      if (this.isLogged) {
        return `/myorders/`;
      }

      return `/?target=login`;
    },
  },
};
</script>
<template>
  <fragment>
    <nav class="tap-bar">
      <a :class="['tap-bar__nav-link-item', { 'tap-bar__nav-link-item--disabled': isMain }]" href="/">
        <svg
          class="tap-bar__nav-link-icon"
          width="28"
          height="34"
          viewBox="0 0 28 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1495 31.9135V16.8256H18.2824V31.9135M1 12.3493L14 1.73779L27 12.3493V29.0245C27 30.699 25.7066 32.0564 24.1111 32.0564H3.88889C2.2934 32.0564 1 30.699 1 29.0245V12.3493Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="tap-bar__nav-link-text">
          Главная
        </p>
      </a>
      <a class="tap-bar__nav-link-item showTapBarMenu" href="#">
        <svg
          class="tap-bar__nav-link-icon"
          width="36"
          height="28"
          viewBox="0 0 36 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.65 6.59113C9.20229 6.59113 9.65 6.14341 9.65 5.59113C9.65 5.03884 9.20229 4.59113 8.65 4.59113V6.59113ZM1 4.59113C0.447715 4.59113 0 5.03884 0 5.59113C0 6.14341 0.447715 6.59113 1 6.59113V4.59113ZM6.355 14.0256C6.90729 14.0256 7.355 13.5778 7.355 13.0256C7.355 12.4733 6.90729 12.0256 6.355 12.0256V14.0256ZM1 12.0256C0.447715 12.0256 0 12.4733 0 13.0256C0 13.5778 0.447715 14.0256 1 14.0256V12.0256ZM8.65 21.46C9.20229 21.46 9.65 21.0123 9.65 20.46C9.65 19.9077 9.20229 19.46 8.65 19.46V21.46ZM1 19.46C0.447715 19.46 0 19.9077 0 20.46C0 21.0123 0.447715 21.46 1 21.46V19.46ZM34.2745 27.6535C34.6545 28.0542 35.2875 28.0709 35.6882 27.6909C36.0889 27.3108 36.1056 26.6778 35.7255 26.2771L34.2745 27.6535ZM29.9739 20.2134C29.5938 19.8127 28.9608 19.796 28.5601 20.1761C28.1594 20.5561 28.1427 21.1891 28.5228 21.5898L29.9739 20.2134ZM8.65 4.59113H1V6.59113H8.65V4.59113ZM6.355 12.0256H1V14.0256H6.355V12.0256ZM8.65 19.46H1V21.46H8.65V19.46ZM21.7778 25.1774C28.2211 25.1774 33.3556 19.6865 33.3556 13.0257H31.3556C31.3556 18.6828 27.0183 23.1774 21.7778 23.1774V25.1774ZM33.3556 13.0257C33.3556 6.36496 28.2211 0.874023 21.7778 0.874023V2.87402C27.0183 2.87402 31.3556 7.36865 31.3556 13.0257H33.3556ZM21.7778 0.874023C15.3344 0.874023 10.2 6.36496 10.2 13.0257H12.2C12.2 7.36865 16.5372 2.87402 21.7778 2.87402V0.874023ZM10.2 13.0257C10.2 19.6865 15.3344 25.1774 21.7778 25.1774V23.1774C16.5372 23.1774 12.2 18.6828 12.2 13.0257H10.2ZM35.7255 26.2771L29.9739 20.2134L28.5228 21.5898L34.2745 27.6535L35.7255 26.2771Z"
            fill="currentColor"
          />
        </svg>
        <p class="tap-bar__nav-link-text">
          Каталог
        </p>
      </a>

      <ClientOnly>
        <a v-if="clientInited" class="tap-bar__nav-link-item " href="/cart/" data-header="bottom">
          <svg
            class="tap-bar__nav-link-icon"
            width="25"
            height="32"
            viewBox="0 0 25 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.92857 13.1829L6.92857 5.04025C6.92857 3.24143 8.39964 1.7832 10.2143 1.7832H14.7857C16.6004 1.7832 18.0714 3.24143 18.0714 5.04025L19.0714 13.1829M2 8.29729L1 26.7539C1 28.5527 2.47106 30.0109 4.28571 30.0109H20.7143C22.5289 30.0109 24 28.5527 24 26.7539L23 8.29729H2Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="tap-bar__nav-link-text">
            <span
              :class="{ 'tap-bar__nav-link-counter--active': hasItems }"
              class="tap-bar__nav-link-counter tap-bar__nav-link-counter--cart cart-counter"
            ></span>
            Корзина
          </p>
        </a>
        <div v-else class="c-button-placeholder"></div>
        <div slot="placeholder" class="c-button-placeholder"></div>
      </ClientOnly>

      <a class="tap-bar__nav-link-item" href="/?target=medical_record_date&action=date">
        <svg
          class="tap-bar__nav-link-icon"
          width="26"
          height="28"
          viewBox="0 0 26 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.61936 28.0092H24.4761C25.0021 28.0092 25.4285 27.5828 25.4285 27.0568V2.8571C25.4285 2.33114 25.0021 1.90473 24.4761 1.90473H17.8096V0.952366C17.8096 0.426406 17.3832 0 16.8572 0H9.23829C8.71233 0 8.28592 0.426406 8.28592 0.952366V1.90473H1.61936C1.0934 1.90473 0.666992 2.33114 0.666992 2.8571V27.0568C0.666992 27.5828 1.0934 28.0092 1.61936 28.0092ZM10.1907 1.90473H15.9048V3.80946H10.1907V1.90473ZM2.57172 3.80946H8.28592V4.76183C8.28592 5.28779 8.71233 5.7142 9.23829 5.7142H16.8572C17.3832 5.7142 17.8096 5.28779 17.8096 4.76183V3.80946H23.5238V26.1044H2.57172V3.80946Z"
            fill="#373737"
          />
          <path
            d="M18.7616 12.7382H15.9045V9.8176C15.9045 9.29164 15.4781 8.86523 14.9522 8.86523H11.1427C10.6167 8.86523 10.1903 9.29164 10.1903 9.8176V12.7382H7.33323C6.80727 12.7382 6.38086 13.1646 6.38086 13.6906V17.5C6.38086 18.026 6.80727 18.4524 7.33323 18.4524H10.1903V21.373C10.1903 21.8989 10.6167 22.3253 11.1427 22.3253H14.9522C15.4781 22.3253 15.9045 21.8989 15.9045 21.373V18.4524H18.7616C19.2876 18.4524 19.714 18.026 19.714 17.5V13.6906C19.714 13.1646 19.2876 12.7382 18.7616 12.7382ZM17.8093 16.5477H14.9522C14.4262 16.5477 13.9998 16.9741 13.9998 17.5V20.4206H12.0951V17.5C12.0951 16.9741 11.6686 16.5477 11.1427 16.5477H8.28559V14.6429H11.1427C11.6686 14.6429 12.0951 14.2165 12.0951 13.6906V10.77H13.9998V13.6906C13.9998 14.2165 14.4262 14.6429 14.9522 14.6429H17.8093V16.5477Z"
            fill="#373737"
          />
        </svg>
        <p class="tap-bar__nav-link-text">
          Запись
        </p>
      </a>

      <ClientOnly>
        <a v-if="clientInited" class="tap-bar__nav-link-item" :href="url">
          <svg
            class="tap-bar__nav-link-icon"
            width="27"
            height="33"
            viewBox="0 0 27 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26 31.0564V26.6722C26 22.9843 23.2018 19.9946 19.75 19.9946H7.25C3.79822 19.9946 1 22.9843 1 26.6722V31.0564M13.5002 14.5312C16.952 14.5312 19.7502 11.6775 19.7502 8.1572C19.7502 4.63694 16.952 1.7832 13.5002 1.7832C10.0485 1.7832 7.25023 4.63694 7.25023 8.1572C7.25023 11.6775 10.0485 14.5312 13.5002 14.5312Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="tap-bar__nav-link-text tap-bar__nav-link-text--name">
            Профиль
          </p>
        </a>
        <div v-else class="c-button-placeholder"></div>
        <div slot="placeholder" class="c-button-placeholder"></div>
      </ClientOnly>
    </nav>
    <div class="tap-bar-menu__overlay">
      <nav class="tap-bar-menu" id="tapBarMenu">
        <a v-for="(item, i) of catalogMenu" :key="`category-${i}`" class="tap-bar-menu__link" :href="item.link">
          <span class="tap-bar-menu-text">{{ item.name }}</span>
          <img v-if="item.image_main" :src="item.image_main" :alt="item.name" class="tap-bar-menu-img" />
        </a>
      </nav>
    </div>
  </fragment>
</template>

<style lang="scss" scoped src="./style.scss"></style>
