<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('cart', ['values']),
    ...mapState('checkout', ['contextItems']),

    hasItems() {
      return this.values.length > 0 || this.contextItems.length > 0;
    },
  },
};
</script>

<template>
  <a class="header__nav-link header__nav-link--relative" href="/cart/" data-header="header">
    <svg
      class="header__nav-link-icon"
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92857 13.1829L6.92857 5.04025C6.92857 3.24143 8.39964 1.7832 10.2143 1.7832H14.7857C16.6004 1.7832 18.0714 3.24143 18.0714 5.04025L19.0714 13.1829M2 8.29729L1 26.7539C1 28.5527 2.47106 30.0109 4.28571 30.0109H20.7143C22.5289 30.0109 24 28.5527 24 26.7539L23 8.29729H2Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span v-if="hasItems" class="header__nav-link-counter cart-counter"> </span>
  </a>
</template>

<style lang="scss" src="./style.scss"></style>
